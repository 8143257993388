import { useState } from "react";
import {
    IconButton,
    TextField,
    FormControl,
    InputAdornment,
    Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import ReCAPTCHA from "react-google-recaptcha";
import querystring from "querystring";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { styled } from "@mui/system";

import { authorizationRequest } from "userRequest";

import { useDispatch } from "react-redux";
import { isLoading } from "Services/actions";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useEventListener } from "../utils/EventListener.js";
import appInsights from "../Services/appInsights.js";

const LoginComponent = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const dispatch = useDispatch();
    const handleLoginButtonClick = useEventListener();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    let validUrls = [];

    async function pingAndSaveValidUrls(baseUrls, access_token) {
        const decodedToken = jwtDecode(access_token);
        let organisation_name = decodedToken?.organisation_name || "";

        const headers = {
            Authorization: `Bearer ${access_token}`,
        };

        let allUrlsFailed = true;

        for (const baseUrl of baseUrls) {
            try {
                const pingResponse = await axios.get(`${baseUrl}/PatientAPI/Ping`, {
                    headers,
                });
                if (pingResponse.status === 200) {
                    validUrls.push(baseUrl);
                    allUrlsFailed = false;
                }
            } catch (error) {
            }
        }
        if (allUrlsFailed) {
            const customProperties = {
                message: `[${organisation_name}] Clinic service is not reachable.`,
                timestamp: new Date().toISOString(),
            };
            appInsights.trackException({
                properties: customProperties,
            });
        }

        if (validUrls.length > 0) {
            localStorage.setItem("validUrls", JSON.stringify(validUrls[0]));
        }
    }

    const handleLogin = async (
        values,
        { setSubmitting, setFieldTouched, setFieldError }
    ) => {
        const requestBody = {
            grant_type: "password",
            username: values.username,
            password: values.password,
            captcha: recaptchaToken,
            scope: "email offline_access openid patientAPI",
        };
        const body = querystring.stringify(requestBody);

        try {
            const response = await authorizationRequest.post("/connect/token", body);
            dispatch(isLoading(true));
            const data = response.data;
            localStorage.setItem("token", data.access_token);
            localStorage.setItem("isLoggedIn", data.access_token);

            if (data.access_token) {
                const decodedToken = jwtDecode(data.access_token);
                let updatedBy = decodedToken?.sub || "";
                localStorage.setItem("sub", updatedBy);
                localStorage.setItem(
                    "organisation_name",
                    decodedToken.organisation_name
                );
            }

            localStorage.setItem("refresh_token", data.refresh_token);
            const baseUrls = jwtDecode(data.access_token).ucc_patient_api.split(";");
            const access_token = data.access_token;
            await pingAndSaveValidUrls(baseUrls, access_token);

            if (localStorage.getItem("token")) {
                if (localStorage.getItem("isLoggedIn") === data.access_token) {
                    const tagName = "BUTTON";
                    const name = "login";
                    const outerText = "LOGIN";
                    handleLoginButtonClick(tagName, name, outerText);
                }
                localStorage.removeItem("isLoggedIn");
                window.location.replace('/home')
            }
        
        } catch (error) {
            dispatch(isLoading(false));
            console.error("Request failed with status code: ", error.response.status);

            setFieldTouched("username", true, false);
            setFieldTouched("password", true, false);

            if (error.response.status === 400) {
                setFieldError("username", " ");
                setFieldError("password", "The Username or Password is Incorrect");
            } else {
                setFieldError("username", " ");
                setFieldError(
                    "password",
                    "There is a technical issue. Please contact Pen CS support"
                );
            }
        } finally {
            dispatch(isLoading(false));
            setSubmitting(false);
        }
    };

    const CustomButton = styled(Button)({
        fontSize: "18px",
        fontWeight: "700",
        borderRadius: "11px",
        background: "#1A485E",
        display: "flex",
        width: "384px",
        height: "50px",
        marginBottom: "9px",
        padding: "12px 35px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: "0",
        "&:hover": {
            backgroundColor: "#12384B",
        },
        textTransform: "none",
    });

    const CustomOutlinedInput = styled(TextField)({
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: "4px",
            },
        },
    });

    const validationSchema = Yup.object().shape({
        username: Yup.string().required(" "),
        password: Yup.string().required("Username or Password cannot be blank"),
    });

    return (
        <>
            <h2 style={styles.welcome_txt}>Welcome</h2>
            <Formik
                initialValues={{
                    username: "",
                    password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
            >
                {({ values, errors, touched, handleChange, setFieldTouched }) => (
                    <Form style={styles.form}>
                        <Field
                            style={{ width: "438px" }}
                            type="text"
                            name="username"
                            label="Username"
                            as={CustomOutlinedInput}
                            variant="outlined"
                            error={touched.username && !!errors.username}
                            onChange={(e) => {
                                handleChange(e);
                                setFieldTouched("username", true, false);
                            }}
                        />

                        <FormControl variant="outlined" style={{ width: "438px" }}>
                            <Field
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                as={CustomOutlinedInput}
                                label="Password"
                                variant="outlined"
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onBlur={() => setFieldTouched("password", true, false)}
                            />
                        </FormControl>

                        <div>
                            <ReCAPTCHA
                                sitekey={"6LdteKEnAAAAALrhz-I-uHoSbNa_rm7haqwfxOK6"}
                                onChange={(token) => {
                                    setRecaptchaToken(token)
                                    setFieldTouched('captcha', true, false)
                                }}
                                onBlur={() => setFieldTouched('captcha', true, false)}
                            />
                            {touched.captcha && errors.captcha && (
                                <div style={{ color: "#D32F2F", fontSize: "12px" }}>
                                    {errors.captcha}
                                </div>
                            )}
                        </div>
                        <div>
                            <CustomButton
                                variant="contained"
                                type="submit"
                                disabled={
                                    !(values.username && values.password && recaptchaToken)
                                }
                            >
                                Login
                            </CustomButton>
                            <a
                                href="https://users.pencs.com.au/admin/sessiontoken/resetpassword.aspx?ReturnUrl=%2fadmin%2fuser.aspx"
                                style={styles.forgot_password}
                            >
                                Forgot your Password?
                            </a>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const styles = {
    welcome_txt: {
        marginBottom: "58px",
        fontSize: "30px",
        fontWeight: "500",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "41px",
    },
    forgot_password: {
        textDecoration: "none",
        cursor: "pointer",
        color: "#0000009E",
        fontSize: "14px",
        marginTop: "9px",
        marginLeft: "8px",
    },
    error: {
        color: "red",
        fontSize: "12px",
        marginTop: "4px",
    },
};

export default LoginComponent;
